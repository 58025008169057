import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Headings from "../../utils/Headings";
import DivContainer from "../../utils/DivContainer";
import Content from "../../utils/Content";
import { styled } from "@mui/material/styles";

export default function TokenNomics() {
  const style = {
    width: "100%",
    padding: 1,
    fontSize: { xs: "20px", md: "26px" },
    textAlign: "left",
    justifyContent: "left",
    py: { xs: 1, md: 1.5 },
  };
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          my: 4,
        }}
      >
        <Headings text="Tokenomics explanation" />
      </Box>
      <DivContainer>
        <Box sx={{ mb: 3 }}>
          <Content text="10 Billion Tokens created" sx={style} />
          <Content text="1 Billion for the team" sx={style} />
          <Content text="2.5 Billion tokens for marketing efforts" sx={style} />
          <Content text="Advisors and partners 500M" sx={style} />
          <Content text="Reserve tokens 2.5 Billion" sx={style} />
          <Content text="Burn @ milestone tokens - 1.5 Billion" sx={style} />

          <Content
            text="Milestone 1: Burn 50M tokens once 250K MCap reached"
            sx={style}
          />
          <Content
            text="Milestone 2: Burn 100M tokens once 500 MCap reached"
            sx={style}
          />
          <Content
            text="Milestone 3: Burn 150M tokens once 750 MCap reached"
            sx={style}
          />
          <Content
            text="Milestone 4: Burn 400M tokens once 1M MCap reached"
            sx={style}
          />
          <Content
            text="Milestone 5: Burn 800M tokens once 2.5M MCap reached"
            sx={style}
          />

          <Content text="Simple taxes - 1% buy/sell in/out" sx={style} />
          <Content
            text="DAO (community) votes how buy/sell taxes are distributed ( marketing, project growth, redistributed-burn,etc. )"
            sx={style}
          />
        </Box>
      </DivContainer>
    </Container>
  );
}
