import "./about.css";
import farmImage from "../assets/barn.png";
import elonMoosk from "../assets/Elon Moosk.png";
import { Box, Grid, Typography } from "@mui/material";
import Headings from "../utils/Headings";
import Content from "../utils/Content";
import CustomButton from "../utils/Button";

export default function About() {
  const openLink = () => {
    window.open(
      "https://raydium.io/swap/?inputMint=sol&outputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
      "_blank"
    );
  };

  return (
    <Box className="main-container">
      <Grid container className="about-container">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="Moopet Home" src={farmImage} className="about-image" />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            // flexDirection: "column",
            minWidth: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Content
            text={
              "Where the MooPetz breed comes from… Professor L. Bull (aka Fatalis) and Professor K. Weinstein (aka Demens), a couple of mad scientists kicked out of Area 51 for their wild experiments, made off with somepotions and animal DNA. After evading Area 51 officials for a year, they set up a secret lab in the outback and started crossbreeding their wild killer bull, with frogs and eventually humans, creating weird hybrids they called Moopetz. Their experiments were a disaster, burning down the lab three times while in the process of breeding the MooPetz. Their crazy creations often escaped, turning their secret outback farm lab into a hilarious mess. Despite all the chaos, Professor Bull and Professor Weinstein keep pushing the limits, always experimenting, wildly..."
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex", // Added display flex to center items vertically
            flexDirection: "column", // Column direction to stack Typography elements
            alignItems: "center", // Center align items horizontally
            textAlign: "center", // Center align text
            justifyContent: "center",
          }}
        >
          <CustomButton onClick={openLink} text="Buy Tokens" />
        </Grid>
      </Grid>
    </Box>
  );
}
